import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MiscService {
  plainHttp: HttpClient
  constructor(
    private http: HttpClient,
    private handler: HttpBackend,
  ) { 
    this.plainHttp = new HttpClient(this.handler);
  }

  contactUs(data: Object){
    return this.http.post(environment.apiUrl + 'contactus', JSON.stringify(data));
  }

  joinPointhippoPlannerWaitlist(data: Object){
    return this.http.post(environment.apiUrl + 'miscellaneous/join-waitlist', JSON.stringify(data));
  }

  hasJoinedPointhippoPlannerWaitlist(){
    return this.http.get(environment.apiUrl + 'miscellaneous/has-joined-waitlist');
  }

  getFaqs(){
    return this.http.get(environment.apiUrl + 'faqs');
  }

  getCurrencies(){
    return this.http.get(environment.apiUrl + 'currencies');
  }

  getGooglePhoto(photoReference: string){
    return this.plainHttp.get(environment.googlePlaceApiUrl + 'photo?key=' + environment.googlePlaceApiKey + '&maxwidth=400&photo_reference=' + photoReference);
  }
  
  getGooglePhotoContent(link: string){
    return this.plainHttp.get(link);
  }

  getGooglePlaceDetails(placeId: string){
    let headers = {
      'Content-Type': 'application/json',
      'X-Goog-Api-Key': environment.googlePlaceApiKey,
      'X-Goog-FieldMask': '*',
    }
    // return this.plainHttp.get(environment.googlePlaceApiUrl + 'details/json?key=' + environment.googlePlaceApiKey + '&place_id=' + placeId , {headers: headers});
    return this.plainHttp.get('https://places.googleapis.com/v1/places/' + placeId , {headers: headers});
  }

  saveGoogleImage(data: Object){
    // return this.http.post('http://localhost/office/pointhippo/saveimage.php', data);
    return this.http.post(environment.baseUrl+'googleimage/saveimage.php', data);
  }

  getHelpTips(){
    return this.http.get(environment.apiUrl + 'helptips');
  }
}
